<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t( translationPath + 'title_income_categories') }}</h1>
      </b-row>
      <account-plan-submenu :active="currentActiveTab"></account-plan-submenu>

      <div class="wrapper_white_with_border pt-4 pb-2">
        <account-plan-incomes-header v-if="$route.name === 'account-plan-incomes-stats'"></account-plan-incomes-header>
        <account-plan-expenses-header v-if="$route.name === 'account-plan-expenses-stats'"></account-plan-expenses-header>
        <account-plan-assets-header v-if="$route.name === 'account-plan-assets-stats'"></account-plan-assets-header>
        <account-plan-liabilities-header v-if="$route.name === 'account-plan-liabilities-stats'"></account-plan-liabilities-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <!-- INCOMES -->
            <template v-if="$route.name === 'account-plan-incomes-stats'">
              <IncomeMenu>
                <template slot="account-plan-stats-incomes">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'income.stats.description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTable :data="items" :busy="busy" :accountType="accountType"/>
                  <b-row no-gutters>
                    <b-col cols="10">
                      <h2 class="mt-4">{{$t(translationPath + 'income.stats.months_with_transactions_title')}}</h2>
                      <div class="text-regular" v-html="$t(translationPath + 'income.stats.months_with_transactions_description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTableWithTransactionYearMonths :accountType="accountType"/>
                </template>
              </IncomeMenu>
            </template>

            <!-- EXPENSES -->
            <template v-else-if="$route.name === 'account-plan-expenses-stats'">
              <ExpenseMenu>
                <template slot="account-plan-stats-expenses">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'expense.stats.description')">!</div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTable :data="items" :busy="busy" :accountType="accountType"/>
                  <b-row no-gutters>
                    <b-col cols="10">
                      <h2 class="mt-4">{{$t(translationPath + 'expense.stats.months_with_transactions_title')}}</h2>
                      <div class="text-regular" v-html="$t(translationPath + 'expense.stats.months_with_transactions_description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTableWithTransactionYearMonths :accountType="'expense'"/>
              </template>
            </ExpenseMenu>
            </template>

            <!-- ASSETS -->
            <template v-else-if="$route.name === 'account-plan-assets-stats'">
              <AssetsMenu>
                <template slot="account-plan-stats-assets">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'asset.stats.description')">!</div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTable :data="items" :busy="busy" :accountType="accountType"/>
                  <b-row no-gutters>
                    <b-col cols="10">
                      <h2 class="mt-4">{{$t(translationPath + 'asset.stats.months_with_transactions_title')}}</h2>
                      <div class="text-regular" v-html="$t(translationPath + 'asset.stats.months_with_transactions_description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTableWithTransactionYearMonths :accountType="'asset'"/>
                </template>
              </AssetsMenu>
            </template>

            <!-- LIABILITIES -->
            <template v-else-if="$route.name === 'account-plan-liabilities-stats'">
              <LiabilityMenu>
                <template slot="account-plan-stats-liabilities">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'liability.stats.description')">!</div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTable :data="items" :busy="busy" :accountType="accountType"/>
                  <b-row no-gutters>
                    <b-col cols="10">
                      <h2 class="mt-4">{{$t(translationPath + 'liability.stats.months_with_transactions_title')}}</h2>
                      <div class="text-regular" v-html="$t(translationPath + 'liability.stats.months_with_transactions_description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsStatsTableWithTransactionYearMonths :accountType="'liability'"/>
                </template>
            </LiabilityMenu>
            </template>

          </b-tabs>
        </b-card>
      </div>
      <!-- END: wrapper_white_with_border -->
    </div>
    <!-- END: main-content-wrapper -->
  </b-container>
</template>

<script>
import IncomeMenu from './submenu/IncomeMenu'
import ExpenseMenu from './submenu/ExpenseMenu'
import AssetsMenu from './submenu/AssetsMenu'
import LiabilityMenu from './submenu/LiabilityMenu'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AccountPlanActionsMixin from './AccountPlanActionsMixin'
import axios from 'axios'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import AccountPlanIncomesHeader from './headers/AccountPlanIncomesHeader'
import AccountPlanExpensesHeader from './headers/AccountPlanExpensesHeader'
import AccountsStatsTable from './AccountsStatsTable'
import AccountsStatsTableWithTransactionYearMonths from './AccountsStatsTableWithTransactionYearMonths'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'
import AccountPlanLiabilitiesHeader from './headers/AccountPlanLiabilitiesHeader'

export default {
  name: 'AccountPlanIncomesOverview',
  mixins: [AccountPlanTabMixin, AccountPlanActionsMixin],
  components: {
    AccountPlanExpensesHeader,
    IncomeMenu,
    ExpenseMenu,
    AssetsMenu,
    LiabilityMenu,
    AccountPlanIncomesHeader,
    AccountPlanAssetsHeader,
    AccountPlanLiabilitiesHeader,
    AccountsStatsTable,
    AccountsStatsTableWithTransactionYearMonths
  },
  data () {
    return {
      items: [],
      currentActiveTab: 'incomes',
      busy: true,
      accountType: ''
    }
  },
  created () {
  },
  methods: {
    async loadData () {
      if (this.$route.name === 'account-plan-incomes-stats') {
        this.currentActiveTab = 'incomes'
        this.accountType = 'income'
      } else if (this.$route.name === 'account-plan-expenses-stats') {
        this.currentActiveTab = 'expenses'
        this.accountType = 'expense'
      } else if (this.$route.name === 'account-plan-assets-stats') {
        this.currentActiveTab = 'assets'
        this.accountType = 'asset'
      } else if (this.$route.name === 'account-plan-liabilities-stats') {
        this.currentActiveTab = 'liabilities'
        this.accountType = 'liability'
      }

      this.busy = true
      const _self = this

      try {
        const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/meta?account_type=${this.accountType}`)
        this.items = response.data.data

        this.items.map((el) => {
          return Object.assign({}, el, {
            title: _self.currentCOA.prefix_account_title_with_code ? displayAccountTitle(el.title, el.code, true) : el.title
          })
        })
      } catch (e) {
        console.log(e.message)
      }
      this.busy = false
    }
  },
  watch: {
    currentCOA: {
      handler: function () {
        if (!this.items.length) {
          this.loadData()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import "./AccountPlan.scss";
</style>
