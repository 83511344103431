<template>
<b-container class="component-accounts-stats-table-with-transaction-year-months">
  <b-row>
    <b-col>
      <b-table class="spirecta-simple-table accounts-stats-table"
        show-empty
        responsive
        striped
        :fields="fields"
        :items="tableData"
        :busy="busy"
        :filter="accountsFilter"
        :filter-included-fields="['title','title_with_code']"
        :emptyFilteredText ="$t('common.no_filter_result')"
      >
        <!--loader-->
        <template v-slot:table-busy>
          <loader />
        </template>
        <!--Custom slots--->

        <!-- CUSTOM SLOT: FILTER -->
        <template slot="top-row">
          <td colspan="13">
            <div class="d-flex align-items-center">
              <i class="fa fa-search text-secondary"></i>
              <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
            </div>
          </td>
        </template>

        <template v-slot:cell(title)="row">
          <b-link :to="'/reports/performance/accounts/'+row.item.account_id+'/view'">
            {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
          </b-link>
        </template>

        <template v-slot:[gomycell(sYearMonth)]="row" v-for="sYearMonth in this.aYearMonths">
          <b-link :key="sYearMonth" :to="'/transactions/view/?account_id='+row.item.account_id+'&start_date='+sYearMonth+'-01&end_date='+getLastDayInMonth(sYearMonth)">{{row.value}}</b-link>
        </template>

      </b-table>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'AccountsStatsTableWithTransactionYearMonths',
  components: {
    Loader
  },
  props: {
    accountType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      translationPath: 'reports.other.account_plan.stats.',
      tableData: [],
      busy: true,
      aYearMonths: [],
      apiData: [],
      accountsFilter: ''
    }
  },
  created () {

  },
  methods: {
    async loadData () {
      try {
        const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 10, 1).toISOString().split('T')[0]
        const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().split('T')[0]

        const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/accounts/with/transactions?start_date=${startDate}&end_date=${endDate}&account_types=${this.accountType}`)
        this.apiData = response.data.data

        for (const index in this.apiData[this.accountType]) {
          const aAccount = {
            title: this.apiData[this.accountType][index].title,
            title_with_code: this.apiData[this.accountType][index].title_with_code,
            account_id: this.apiData[this.accountType][index].id,
            _cellVariants: {}
          }
          for (const iYearMonth in this.apiData[this.accountType][index].year_month) {
            aAccount[iYearMonth] = this.apiData[this.accountType][index].year_month[iYearMonth]
            if (this.apiData[this.accountType][index].year_month[iYearMonth] > 0) {
              aAccount._cellVariants[iYearMonth] = 'success'
            } else {
              aAccount._cellVariants[iYearMonth] = 'warning'
            }
          }
          this.tableData.push(aAccount)
        }
        this.aYearMonths = []
        for (const index in this.apiData.year_months) {
          this.aYearMonths.push(index)
        }
      } catch (e) {
        console.log(e.message)
      }
      this.busy = false
    },
    gomycell (key) {
      return `cell(${key})`
    },
    getLastDayInMonth (sYearMonth) {
      const sReturnDate = new Date((new Date(sYearMonth.substring(0, 4), sYearMonth.substring(5, 7), 1)) - 1)

      return sReturnDate.toISOString().split('T')[0]
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    local () {
      return this.currentUser.default_lang
    },
    fields () {
      const aFields = [
        { key: 'title', label: this.$t(this.translationPath + 'table.title_' + this.accountType), sortable: false }
      ]

      if (this.busy === false) {
        for (const index in this.apiData.year_months) {
          aFields.push({
            key: index, class: 'text-center'
          })
        }
      }

      return aFields
    }
  },
  watch: {
    currentCOA: {
      handler: function (newVal) {
        if (!this.tableData.length) {
          this.loadData()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-accounts-stats-table-with-transaction-year-months{
  .accounts-stats-table{
    td, th{
      font-size:90%;
    }
  }
}
</style>
