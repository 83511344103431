<template>
<b-container class="component-accounts-stats-table">
  <b-row>
    <b-col>
      <b-table class="spirecta-simple-table accounts-stats-table"
        show-empty
        hover
        responsive
        striped
        :fields="fields"
        :items="items"
        :busy="busy"
        :filter="accountsFilter"
        :filter-included-fields="['title','title_with_code']"
        :emptyFilteredText ="$t('common.no_filter_result')"
      >
        <!--loader-->
        <template v-slot:table-busy>
          <loader />
        </template>
        <!--Custom slots--->

        <!-- CUSTOM SLOT: FILTER -->
        <template slot="top-row">
          <td colspan="8">
            <div class="d-flex align-items-center">
              <i class="fa fa-search text-secondary"></i>
              <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
            </div>
          </td>
        </template>

        <!-- TITLE -->
        <template v-slot:cell(title)="row">
          <b-link :to="'/reports/performance/accounts/'+row.item.account_id+'/view'">
            {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
          </b-link>
        </template>

        <template v-slot:cell(first_transaction)="row">
          <span :title="row.unformatted">{{row.value}}</span>
        </template>

        <template v-slot:cell(last_transaction)="row">
          <span :title="row.unformatted">{{row.value}}</span>
        </template>

        <template v-slot:cell(count_transactions)="row">
          <b-link :to="'/transactions/view/?account_id='+row.item.account_id">{{row.value}}</b-link>
        </template>

      </b-table>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'AccountsStatsTable',
  components: {
    Loader
  },
  filters: {
    formatAmount
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    accountType: {
      type: String,
      default: 'income'
    },
    busy: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      translationPath: 'reports.other.account_plan.stats.',
      accountsFilter: ''
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    local () {
      return this.currentUser.default_lang
    },
    items () {
      if (!this.data || this.data.length === 0) return []
      return this.data
    },
    fields () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table.title_' + this.accountType), sortable: false },
        { key: 'amount_sum', label: (this.accountType === 'income' || this.accountType === 'expense' ? this.$t(this.translationPath + 'table.amount_sum') : this.$t(this.translationPath + 'table.amount_balance')), sortable: false, class: 'text-right', formatter: 'amountFormatter' },
        { key: 'last_transaction', label: this.$t(this.translationPath + 'table.last_transaction_date'), sortable: false, formatter: 'dateFormatter', class: 'text-right' },
        { key: 'first_transaction', label: this.$t(this.translationPath + 'table.first_transaction_date'), sortable: false, formatter: 'dateFormatter', class: 'text-right' },
        { key: 'count_transactions', label: this.$t(this.translationPath + 'table.cnt_transactions'), sortable: false, class: 'text-right', formatter: 'amountFormatter' },
        { key: 'amount_min', label: this.$t(this.translationPath + 'table.amount_min'), sortable: false, class: 'text-right', formatter: 'amountFormatter' },
        { key: 'amount_max', label: this.$t(this.translationPath + 'table.amount_max'), sortable: false, class: 'text-right', formatter: 'amountFormatter' },
        { key: 'amount_average', label: this.$t(this.translationPath + 'table.amount_average'), sortable: false, class: 'text-right', formatter: 'amountFormatter' }
      ]
    }
  },
  methods: {
    amountFormatter (value) {
      return formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0)
    },
    dateFormatter (value) {
      if (value !== undefined && value !== null) {
        return value.substring(2, 4) + value.substring(5, 7) + value.substring(8, 10)
      }
    }
  }
}
</script>

<style lang="scss">
.component-accounts-stats-table{
  .accounts-stats-table{
    td:first-child{
      max-width:40%;
    }
    td, th{
      font-size:90%;
    }
  }
}
</style>
